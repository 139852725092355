<template>
  <section class="min-w1600">
    <div class="searchwrap">
      <div class="searchBar">
        <button type="button" class="btn-layout btn-grey" @click="detailModalOpen('c')">{{ $t('button.add') }}</button>
      </div>
    </div>
    <div class="main-contents-wrapper">
      <article class="total-info-container">
        <div class="total-info-wrap">
          <div class="total-info-item">
            <img :src="require('@/assets/img/searchicon.png')" />
            <span class="title">{{ $t(`totalInfo.tatal_list_count`) }}</span> : <span class="value">0</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.page`) }}</span> : <span class="value">0</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_page_count`) }}</span> : <span class="value">0</span>
          </div>
        </div>
      </article>
      <div class="table-wrapper">
        <table class="mainTable">
          <table-head :headInfo="headInfo" />
          <tbody>
            <tr>
              <td>12</td>
              <td>가상 계좌 이체 오류 답변</td>
              <td>다이아</td>
              <td>2022-03-24 19:12:18</td>
              <td>2022-03-24 19:12:18</td>
              <td style="width: 10%">
                <div class="status-change-btn-wrap">
                  <button class="btn-innerTable btn-status-change wait" type="button">{{ $t('button.modify') }}</button>
                  <button class="btn-innerTable btn-status-change refuse" type="button">{{ $t('button.del') }}</button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <pagination v-if="tableData.length !== 0" @goPage="setTableData" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />

    <div class="modal-wrapper" v-if="updModalActive">
      <div class="modal-wrap">
        <article>
          <div class="sub-title">
            <h3>{{ $t('button.add') }}</h3>
          </div>
          <div class="page-content">
            <div class="item-row-container">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 8.5%">{{ $t('table.head.qTitle2') }}</div>
                <div class="item-content">
                  <input type="text" v-model="boardDetail.title" />
                </div>
              </div>
            </div>
            <div class="item-row-container">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 8.5%">{{ $t('table.head.content') }}</div>
                <div class="item-content"><textarea v-model="boardDetail.content"></textarea></div>
              </div>
            </div>
            <div class="status-change-btn-wrap pt50">
              <button class="btn-innerTable btn-status-change approve" v-if="modalType !== 'c'" type="button" @click="updateBoard(boardDetail.boardIdx)">{{ $t('button.save') }}</button>
              <button class="btn-innerTable btn-status-change approve" v-if="modalType === 'c'" type="button" @click="createBoard()">추가</button>
              <button class="btn-innerTable btn-status-change approve" type="button" @click="modalClose()">{{ $t('button.close') }}</button>
            </div>
          </div>
        </article>
      </div>
    </div>
  </section>
</template>

<script>
import lodash from 'lodash'
import TableHead from '@/components/main/table/Head.vue'
import DateSelector from '@/components/common/DateSelector'
import Pagination from '@/components/common/Pagination'
import { getSiteData } from '@/libs/auth-helper'
import { boardList, boardDetail, boardSave } from '@/api/member.js'
import { replaceDateT, numberWithCommas, getDateStr } from '@/libs/utils.js'
export default {
  name: 'BoardQna',
  components: {
    TableHead,
    Pagination,
    DateSelector
  },
  data () {
    return {
      tableName: this.$route.name,
      headInfo: {
        fstColumn: false,
        dataList: ['index', 'aof', 'regId', 'regDate', 'updDate2', 'option']
      },
      modalType: '',
      reqData: {
        page: 1,
        count_per_list: 30,
        count_per_page: 30,
        boardType: 'answer'
      },
      boardType: 'answer',
      tableData: [],
      pageInfo: {},
      totalInfo: {},
      updModalActive: false
    }
  },
  methods: {
    setStartDate (date) {
      // console.log(date);
      let _date = ''
      if (!date) {
        _date = new Date()
      } else {
        _date = date[0]
      }
      this.startDefault = _date
      this.reqData.startDate = getDateStr(_date, 'yyyy-MM-dd')
    },
    setEndDate (date) {
      // console.log(date[0]);
      let _date = ''
      if (!date) {
        _date = new Date()
      } else {
        _date = date[0]
      }
      this.endDefault = _date
      this.reqData.endDate = getDateStr(_date, 'yyyy-MM-dd')
    },
    goSearch (page) {
      this.setTableData(page)
    },
    pageSeach () {
      this.setTableData()
    },
    async deleteContent (boardIdx) {
      const trigger = confirm('해당 게시물을 삭제하시겠습니까?')
      if (!trigger) {
        return
      }
      const boardType = this.boardType
      const req = {
        boardType,
        boardIdx,
        delYn: 'Y'
      }

      const res = await boardSave(boardType, req)
      console.log(res)
      if (res.resultCode === '0') {
        alert('게시물 삭제 완료')
        this.setTableData()
      } else {
        alert('게시물 삭제 실패, 다시 시도해주세요.')
      }
    },
    async updateBoard (boardIdx) {
      const trigger = confirm('해당 게시물을 수정하시겠습니까?')
      if (!trigger) {
        return
      }
      const boardType = this.boardType
      const req = {
        boardIdx,
        title: this.boardDetail.title,
        content: this.boardDetail.content,
        boardType
      }
      const res = await boardSave(this.boardType, req)
      console.log(res)
      if (res.resultCode === '0') {
        alert('게시물 수정 완료')
        this.setTableData()
        this.modalClose()
      } else {
        alert('게시물 수정 실패, 다시 시도해주세요.')
      }
    },
    getSiteInfo () {
      const siteInfoCookie = getSiteData()
      if (siteInfoCookie) {
        const siteInfo = JSON.parse(siteInfoCookie)
        return siteInfo
      }
    },
    async createBoard () {
      const siteInfo = this.getSiteInfo()
      const trigger = confirm('해당 게시물을 등록 하시겠습니까?')
      if (!trigger) {
        return
      }
      const boardType = this.boardType
      const req = {
        title: this.boardDetail.title,
        content: this.boardDetail.content,
        boardType
      }
      console.log(req)
      const res = await boardSave(this.boardType, req)
      console.log(res)
      if (res.resultCode === '0') {
        alert('게시물 등록 완료')
        this.setTableData()
        this.modalClose()
      } else {
        alert('게시물 등록 실패, 다시 시도해주세요.')
      }
    },
    modalClose () {
      this.updModalActive = false
      this.boardDetail = {}
    },
    detailModalOpen (type) {
      this.modalType = type
      if (type === 'c') {
        this.boardDetail = {}
      }
      this.updModalActive = true
    },
    async getContentDetail (boardIdx) {
      const req = {
        boardIdx
      }
      const res = await boardDetail(this.boardType, req)
      this.boardDetail = res.data.board
      console.log(this.boardDetail)
      this.detailModalOpen()
    },
    async setTableData (page) {
      if (page) {
        this.reqData.page = page
      } else {
        this.reqData.page = 1
      }
      const req = lodash.cloneDeep(this.reqData)
      const boardType = 'answer'
      const res = await boardList(boardType, req)
      console.log(res)
      if (res.data.pageInfo) {
        this.pageInfo = res.data.pageInfo
        this.pageInfo.tatal_list_count = numberWithCommas(this.pageInfo.tatal_list_count)
        this.pageInfo.tatal_page_count = numberWithCommas(Number(this.pageInfo.tatal_page_count) + 1)
      }
      this.tableData = res.data.list
    }
  },
  async created () {
    this.setTableData(1)
  }
}
</script>

<style scoped>
.sub-title {
  font-size: 1em;
  font-weight: 700;
  color: var(--main);
  height: 32px;
  position: relative;
  margin-bottom: 20px;
  border-bottom: 2px solid var(--main);
}
.sub-title > h3 {
  display: flex;
  height: 100%;
  align-items: center;
  width: 180px;
  font-size: 0.9em;
  border-start-end-radius: 10px;
  border-start-start-radius: 10px;
}
.sub-title > h3::before {
  content: "●";
  color: #18698b;
  margin-right: 7px;
}
textarea {
  width: 100%;
  min-height: 160px;
  border: 1px solid #18698b;
  resize: none;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 3px;
}
.pt50 {
  padding-top: 50px;
}
.ml10 {
  margin-left: 10px;
}
.modal-wrapper {
  display: flex;
  font-size: 16px;
}
</style>
